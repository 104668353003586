import React from 'react'; 
import { motion } from 'framer-motion'; 
import './contactfooter.css';  

const Contact = () => {
  return (
    <motion.div
      id="contact"
      initial={{ x: -100, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 1 }}
      className="contact"
    >
      <div className="contact-container">
        <div className="coming-soon-text">
          <h2 className="coming-soon-title">Coming Soon</h2>
          <p className="coming-soon-subtitle">
            Second Phase <span className="collection-drop">Collection Drop</span>
          </p>
        </div>
      </div>
    </motion.div>
  );
};

const Footer = () => {
  return (
    <div className="footer-background">
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-icons">
            <motion.a
              href="https://discord.gg/J2j9bZAm"
              target="_blank"
              rel="noopener noreferrer"
              whileHover={{ scale: 1.1, rotate: 5 }}
              transition={{ duration: 0.3 }}
            >
              <img src="/discord.svg" alt="Discord" className="footer-icon" />
            </motion.a>
            <motion.a
              href="https://x.com/parrottownNFT"
              target="_blank"
              rel="noopener noreferrer"
              whileHover={{ scale: 1.1, rotate: 5 }}
              transition={{ duration: 0.3 }}
            >
              <img src="/twitter.png" alt="Twitter" className="footer-icon" />
            </motion.a>
          </div>
          
          <p className="footer-text">
            All copyrights reserved @ parrottown2024
          </p>
        </div>
      </footer>
    </div>
  );
};

const ContactAndFooterSection = () => {
  return (
    <div className="relative">
      <Contact />
      <Footer />
    </div>
  );
};

export default ContactAndFooterSection;