import React, { useEffect } from 'react';
import './hero.css';

const Hero = () => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('slide-in');
          }
        });
      },
      { threshold: 0.1 }
    );

    // Select Hero-specific elements for animations
    const heroElements = document.querySelectorAll('.hero-content');
    heroElements.forEach((el) => observer.observe(el));

    return () => observer.disconnect();
  }, []);

  return (
    <div className="hero">
      {/* Background Image */}
      <div className="hero-background">
        <img src="/Home.png" alt="Hero Background" />
      </div>

      {/* Hero Content */}
      <div className="hero-content">
        <div className="text-wrapper">
          <h1>
            <span>PAGES FROM</span>
            <span className="parrot-town">PARROT TOWN</span>
          </h1>
          <b>PUNK PARROTS IN VERDANT VEIL!</b>
        </div>
      </div>
    </div>
  );
};

export default Hero;