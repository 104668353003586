import { useCallback } from "react";
import styles from "./Mob.module.css";

const Mob = () => {
  const onFrameContainerClick = useCallback(() => {
    // Please sync "menu" to the project
  }, []);

  return (
    <div className={styles.mob}>
      <div className={styles.contact}>
        <div className={styles.frameParent}>
          <div className={styles.lookingToGetInvolvedParent}>
            <div className={styles.lookingToGet}></div>
            <div className={styles.contactUs}></div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.yourNameParent}>
                <div className={styles.yourName}></div>
                
              </div>
              <div className={styles.eMailIdParent}>
                <div className={styles.yourName}></div>
              </div>
              <div className={styles.yourNameParent}>
                <div className={styles.yourName}></div>
              </div>
            </div>
            <div className={styles.supportTeamWill}>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.about}>
        <div className={styles.frameDiv}>
          <div className={styles.frameParent1}>
            <div className={styles.unveilingStoryParent}>
              <div className={styles.lookingToGet}>UNVEILING STORY</div>
              <div className={styles.ofArasCanopy}>OF ARA’S CANOPY!</div>
            </div>
            <div className={styles.contraryToPopularBeliefLoWrapper}>
              <div className={styles.contraryToPopular}>
                Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literature from
                45 BC, making it over 2000 years old. Richard McClintock, a
                Latin professor at Hampden-Sydney College in Virginia, looked up
                one of the more obscure Latin words, consectetur, from a Lorem
                Ipsum passage, and going through the cites of thE word in
                classical literature, discovered the undoubtable source. Lorem
                Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus
                Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero,
                written in 45 BC. This book is a treatise on the theory of
                ethics, very popular during the Renaissance. The first line of
                Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line
                in section 1.10.32.
              </div>
            </div>
          </div>
          <div className={styles.groupParent}>
            <img className={styles.groupIcon} alt="" src="/mob/group-35@2x.png" />
            <img className={styles.groupIcon} alt="" src="/mob/group-34@2x.png" />
          </div>
        </div>
      </div>
      <div className={styles.join}>
        <div className={styles.groupContainer}>
          <div className={styles.groupDiv}>
            <img className={styles.groupChild} alt="" src="/mob/group-59@2x.png" />
            <div className={styles.joinTheFlockContainer}>
            </div>
          </div>
          <div className={styles.frameWrapper}>
            <div className={styles.inArasCanopyTheHeartOfWrapper}>
              <div className={styles.inArasCanopy}>
                In Ara’s Canopy, the heart of the town lies in its community—the
                vibrant flock of NFT holders and enthusiasts. Here, every parrot
                has a voice, and together, we shape the future of this mystical
                cyberpunk town nestled in the Verdant Veil.
              </div>
            </div>
          </div>
          <div className={styles.groupParent1}>
          <img className={styles.frameChild2} alt="" src="/mob/group-13@2x.png" />
          </div>
          <div className={styles.subtractParent}>
  <a href="https://discord.gg/J2j9bZAm" target="_blank" rel="noopener noreferrer">
    <img className={styles.subtractIcon} alt="Discord" src="/mob/Subtract.png" />
  </a>
  <a href="https://x.com/parrottownNFT" target="_blank" rel="noopener noreferrer">
    <img className={styles.frameChild3} alt="Twitter" src="/mob/tw.png" />
  </a>
</div>

        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.footerInner}>
          <img className={styles.frameChild4} alt="" src="/mob/group-38@2x.png" />
        </div>
        <div className={styles.frameParent2}>
         <div className={styles.subtractGroup}>
  {/* Link for Discord */}
  <a href="https://discord.gg/J2j9bZAm" target="_blank" rel="noopener noreferrer">
    <img className={styles.subtractIcon1} alt="Discord" src="/mob/subtract1.svg" />
  </a>

  {/* Link for Twitter */}
  <a href="https://x.com/parrottownNFT" target="_blank" rel="noopener noreferrer">
    <img className={styles.frameChild5} alt="Twitter" src="/mob/group-91@2x.png" />
  </a>
</div>

          <div className={styles.lookingToGet}>
            All Copyrights Reserved @Parrottown 2024
          </div>
        </div>
      </div>
      <div className={styles.hero}>
        <div className={styles.heroInner}>
          <img className={styles.frameChild6} alt="" src="/mob/group-62@2x.png" />
        </div>
        <div className={styles.frameParent3}>
          <div className={styles.frameWrapper1}>
            <div className={styles.vectorParent}>
              <img className={styles.vectorIcon} alt="" src="/mob/vector-26.svg" />
              <div
                className={styles.frameWrapper2}
                onClick={onFrameContainerClick}
              >
                <div className={styles.vectorGroup}>
  <img
    className={styles.menuImage}
    alt="Menu"
    src="/mob/menu.png"
  />
</div>

              </div>
            </div>
          </div>
          <div className={styles.frameWrapper3}>
            <div className={styles.pagesFromParrotTownParent}>
              <div className={styles.pagesFromParrot}>
                PAGES FROM PARROT TOWN
              </div>
              <div className={styles.punkParrotsIn}>
                PUNK PARROTS IN VERDANT VEIL!
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.story}>
        <div className={styles.storyInner}>
          <img className={styles.frameChild10} alt="" src="/mob/group-32@2x.png" />
        </div>
        <div className={styles.frameParent4}>
          <div className={styles.frameWrapper4}>
            <div className={styles.groupParent2}>
              <img className={styles.frameChild11} alt="" src="/mob/group-55.svg" />
              <div className={styles.frameWrapper5}>
                <div className={styles.frameParent5}>
                  <div className={styles.clearanceStatusParent}>
                    <div className={styles.lookingToGet}>CLEARANCE STATUS</div>
                    <div className={styles.welcomeToThe}>
                      Welcome to the Flight Academy, where every parrot in Ara’s
                      Canopy earns its wings! Your clearance status determines
                      your rank within the flock, and with higher ranks come
                      special privileges.
                    </div>
                  </div>
                  <div className={styles.frameParent6}>
                    <div className={styles.advantagesOfBuyingThisNftParent}>
                      <div className={styles.lookingToGet}>
                        ADVANTAGES OF BUYING THIS NFT!
                      </div>
                      <div className={styles.getReadyTo}>
                        Get ready to take flight as we reveal why owning an
                        Ara’s Canopy NFT isn’t just a purchase, it’s an
                        adventure into the skies of the Verdant Veil.
                      </div>
                    </div>
                    <div className={styles.frameParent7}>
                      <div className={styles.frameParent8}>
                        <div className={styles.ellipseWrapper}>
                          <div className={styles.ellipseDiv} />
                        </div>
                        <div className={styles.number01ThePowerOfAugmeParent}>
                          <div className={styles.lookingToGet}>
                            NUMBER 01 - THE POWER OF AUGMENTATIONS
                          </div>
                          <div className={styles.whySettleForContainer}>
                            <p
                              className={styles.joinTheFlock}
                            >{`Why settle for just wings, when you can have wings powered by the finest cyber-tech Ara could steal?
`}</p>
                            <p className={styles.joinTheFlock}>
                              Each NFT parrot comes with its own unique set of
                              cyberpunk enhancements. From lightning-fast
                              reflexes to night-vision goggles, these
                              modifications aren’t just for show—they’re your
                              ticket to exclusive events and rare story content
                              in the Canopy. The more advanced the augmentation,
                              the greater the access!
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={styles.frameParent9}>
                        <div className={styles.ellipseWrapper}>
                          <div className={styles.ellipseDiv} />
                        </div>
                        <div className={styles.number02FlockMembershipParent}>
                          <div className={styles.lookingToGet}>
                            NUMBER 02 - FLOCK MEMBERSHIP
                          </div>
                          <div className={styles.onceAPartContainer}>
                            <p
                              className={styles.joinTheFlock}
                            >{`Once a part of Ara’s Canopy, always a part of Ara’s Canopy.”
`}</p>
                            <p
                              className={styles.joinTheFlock}
                            >{`As an NFT holder, you become part of an exclusive community. You’ll receive:

Special Event Invites:`}</p>
                            <p className={styles.joinTheFlock}>
                              Get VIP access to both virtual and physical
                              meet-ups, including AR/VR experiences that bring
                              the world of Ara’s Canopy to life.
                            </p>
                            <p className={styles.joinTheFlock}>{`
Story Access: `}</p>
                            <p className={styles.joinTheFlock}>
                              Unlock hidden chapters of the evolving parrot
                              story. Who knows? Your parrot might just be the
                              hero of the next saga.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={styles.frameParent10}>
                        <div className={styles.ellipseWrapper}>
                          <div className={styles.ellipseDiv} />
                        </div>
                        <div className={styles.number03SecretUpdatesAndParent}>
                          <div className={styles.lookingToGet}>
                            NUMBER 03 - SECRET UPDATES AND HIDDEN TECH!
                          </div>
                          <div className={styles.whySettleForContainer}>
                            <p
                              className={styles.joinTheFlock}
                            >{`The forest is full of mysteries… only the most trusted parrots know where to find them.”
`}</p>
                            <p className={styles.joinTheFlock}>
                              Being an NFT owner grants you clearance to
                              periodic drops of hidden technology. These digital
                              enhancements, exclusive to the Ara’s Canopy
                              community, are usable within your NFT for new,
                              visually striking traits or even real-world
                              bonuses tied to our future drops.
                            </p>
                            <p className={styles.joinTheFlock}>&nbsp;</p>
                          </div>
                        </div>
                      </div>
                      <div className={styles.frameParent11}>
                        <div className={styles.ellipseWrapper}>
                          <div className={styles.ellipseDiv} />
                        </div>
                        <div className={styles.number04GovernanceAndDecParent}>
                          <div className={styles.lookingToGet}>
                            NUMBER 04 - GOVERNANCE AND DECISION-MAKING!
                          </div>
                          <div className={styles.whySettleForContainer}>
                            <p
                              className={styles.joinTheFlock}
                            >{`The flock flies as one. The future of the Canopy is in your feathers!”
`}</p>
                            <p className={styles.joinTheFlock}>
                              Each parrot NFT comes with voting rights in key
                              decisions for the future of the project. Do we
                              venture into new digital forests? Do we enhance
                              the cyber augments? Holders will have a say in the
                              evolution of the town and its lore.
                            </p>
                            <p className={styles.joinTheFlock}>&nbsp;</p>
                          </div>
                        </div>
                      </div>
                      <div className={styles.frameParent12}>
                        <div className={styles.ellipseWrapper}>
                          <div className={styles.ellipseDiv} />
                        </div>
                        <div className={styles.number03SecretUpdatesAndParent}>
                          <div className={styles.lookingToGet}>
                            NUMBER 05 - MERCH PRIVILEGES
                          </div>
                          <div className={styles.whySettleForContainer}>
                            <p
                              className={styles.joinTheFlock}
                            >{`Exclusive Merch, Wear your feathers proudly!
`}</p>
                            <p className={styles.joinTheFlock}>
                              As a parrot owner, you’ll have access to
                              limited-edition physical and digital merchandise.
                              Whether it’s stylish cyberpunk parrot gear or
                              exclusive collectibles, flaunt your status in both
                              the digital and real world.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={styles.frameParent13}>
                        <div className={styles.ellipseWrapper}>
                          <div className={styles.ellipseDiv} />
                        </div>
                        <div className={styles.number06AirdropsAndSurprParent}>
                          <div className={styles.lookingToGet}>
                            NUMBER 06 - AIRDROPS AND SURPRISES!
                          </div>
                          <div className={styles.whySettleForContainer}>
                            <p
                              className={styles.joinTheFlock}
                            >{`Expect the unexpected in Ara’s Canopy.”
`}</p>
                            <p className={styles.joinTheFlock}>
                              The Verdant Veil is full of secrets, and as an NFT
                              holder, you can expect periodic airdrops of
                              exclusive content, upgrades, or even new parrots.
                              From new cybernetic augmentations to exclusive
                              art, you never know what’s waiting for you in your
                              nest.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameWrapper6}>
            <div className={styles.inArasCanopyTheSkyIsntWrapper}>
              <div className={styles.inArasCanopy1}>
                “In Ara’s Canopy, the sky isn’t the limit—it’s only the
                beginning. So spread your wings, gear up, and take your rightful
                place among the stars.”
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.faq}>
        <div className={styles.faqInner}>
          <img className={styles.frameChild17} alt="" src="/mob/group-14@2x.png" />
        </div>
        <div className={styles.frameParent14}>
          <div className={styles.eMailIdParent}>
            <div className={styles.lookingToGetInvolvedParent}>
              <div className={styles.lookingToGet}>FAQs</div>
            </div>
            <img className={styles.frameChild18} alt="" src="/mob/vector-3.svg" />
          </div>
          <div className={styles.frameParent16}>
            <div className={styles.frameParent17}>
              <div className={styles.whatIsArasCanopyParent}>
                <div className={styles.whatIsAras}>What is Ara’s Canopy?</div>
                <img
                  className={styles.polygon3Stroke}
                  alt=""
                  src="/mob/polygon-3-stroke.svg"
                />
              </div>
              <img
                className={styles.frameChild18}
                alt=""
                src="/mob/vector-31.svg"
              />
            </div>
            <div className={styles.frameParent17}>
              <div className={styles.whatIsArasCanopyParent}>
                <div className={styles.whatIsAras}>How do I Mint a Parrot?</div>
                <img
                  className={styles.polygon3Stroke}
                  alt=""
                  src="/mob/polygon-3-stroke.svg"
                />
              </div>
              <img
                className={styles.frameChild18}
                alt=""
                src="/mob/vector-31.svg"
              />
            </div>
            <div className={styles.frameParent17}>
              <div className={styles.whatIsArasCanopyParent}>
                <div className={styles.whatIsAras}>
                  What blockchain is Ara’s Canopy using?
                </div>
                <img
                  className={styles.polygon3Stroke}
                  alt=""
                  src="/mob/polygon-3-stroke.svg"
                />
              </div>
              <img
                className={styles.frameChild18}
                alt=""
                src="/mob/vector-31.svg"
              />
            </div>
            <div className={styles.frameParent17}>
              <div className={styles.whatIsArasCanopyParent}>
                <div className={styles.whatIsAras}>
                  What makes each parrot NFT unique?
                </div>
                <img
                  className={styles.polygon3Stroke}
                  alt=""
                  src="/mob/polygon-3-stroke.svg"
                />
              </div>
              <img
                className={styles.frameChild18}
                alt=""
                src="/mob/vector-31.svg"
              />
            </div>
            <div className={styles.frameParent17}>
              <div className={styles.whatIsArasCanopyParent}>
                <div className={styles.whatIsAras}>
                  What are the benefits of owning a parrot NFT?
                </div>
                <img
                  className={styles.polygon3Stroke}
                  alt=""
                  src="/mob/polygon-3-stroke.svg"
                />
              </div>
              <img
                className={styles.frameChild18}
                alt=""
                src="/mob/vector-31.svg"
              />
            </div>
            <div className={styles.frameParent17}>
              <div className={styles.whatIsArasCanopyParent}>
                <div className={styles.whatIsAras}>
                  Can I sell my parrot NFT?
                </div>
                <img
                  className={styles.polygon3Stroke}
                  alt=""
                  src="/mob/polygon-3-stroke.svg"
                />
              </div>
              <img
                className={styles.frameChild18}
                alt=""
                src="/mob/vector-31.svg"
              />
            </div>
            <div className={styles.frameParent17}>
              <div className={styles.whatIsArasCanopyParent}>
                <div className={styles.whatIsAras}>
                  How do I join the Ara’s Canopy community?
                </div>
                <img
                  className={styles.polygon3Stroke}
                  alt=""
                  src="/mob/polygon-3-stroke.svg"
                />
              </div>
              <img
                className={styles.frameChild18}
                alt=""
                src="/mob/vector-31.svg"
              />
            </div>
            <div className={styles.frameParent17}>
              <div className={styles.whatIsArasCanopyParent}>
                <div className={styles.whatIsAras}>
                  What wallets are compatible with Ara’s Cano...
                </div>
                <img
                  className={styles.polygon3Stroke}
                  alt=""
                  src="/mob/polygon-3-stroke.svg"
                />
              </div>
              <img
                className={styles.frameChild18}
                alt=""
                src="/mob/vector-31.svg"
              />
            </div>
            <div className={styles.frameParent17}>
              <div className={styles.whatIsArasCanopyParent}>
                <div className={styles.whatIsAras}>
                  What can I do if I encounter an issue during...
                </div>
                <img
                  className={styles.polygon3Stroke}
                  alt=""
                  src="/mob/polygon-3-stroke.svg"
                />
              </div>
              <img
                className={styles.frameChild18}
                alt=""
                src="/mob/vector-31.svg"
              />
            </div>
            <div className={styles.frameParent17}>
              <div className={styles.whatIsArasCanopyParent}>
                <div className={styles.whatIsAras}>
                  How can I stay updated with the latest news?
                </div>
                <img
                  className={styles.polygon3Stroke}
                  alt=""
                  src="/mob/polygon-3-stroke.svg"
                />
              </div>
              <img
                className={styles.frameChild18}
                alt=""
                src="/mob/vector-31.svg"
              />
            </div>
          </div>
        </div>
      </div>
      <img
  className={styles.mobChild}
  alt="Scroll to Hero"
  src="/mob/group-37@2x.png"
  onClick={() => {
    const heroSection = document.querySelector(`.${styles.hero}`);
    if (heroSection) {
      heroSection.scrollIntoView({ behavior: "smooth" });
    }
  }}
/>

    </div>
  );
};

export default Mob;
